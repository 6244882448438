import React, { useState } from "react";
import WhiteLogo from "../img/logo_white.svg";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link } from 'react-router-dom';


const Header = ({language}) => {
    const [showNav, setShowNav] = useState(false);

    const toggleNav = () => {
        setShowNav(!showNav);
    };

    const changeLanguage = () => {
        if (language === 'zh') {
            localStorage.setItem('language', 'en');
            console.log(localStorage);
            window.location.href = '/';
        } else {
            localStorage.setItem('language', 'zh');
            window.location.href = '/';
        }
    }

    return (
        <nav className="home-nav">
            <div>
                <a href="/home">
                    <div className="home-logo">
                        <img src={WhiteLogo} alt="" />
                    </div>
                </a>
                <ul className="home-link">
                    <a href="/">{language === 'zh' ? '主页' : 'Home'}</a>
                    <a href="/#design">{language === 'zh' ? '服务' : 'Services'}</a>
                    <a href="/news">{language === 'zh' ? '新闻' : 'News'}</a>
                    <a href="/#About">{language === 'zh' ? '关于' : 'About'}</a>
                    <a href="https://forms.office.com/r/4AiVxeQLmp">{language === 'zh' ? '联系我们' : 'Contact'}</a>
                    <div style={{color: '#fff', marginLeft: '20px', cursor: 'pointer'}} onClick={changeLanguage}>中 | EN</div>
                </ul>
                <div className="mobile-menu-icon" onClick={toggleNav}>
                    {showNav ? <IoClose style={{ color: 'white', fontSize: '30px',  }} /> : <FaBars style={{ color: 'white', fontSize: '30px' }} />}
                </div>
                {showNav && (
                    <ul className="home-link-mobile">
                        <Link onClick={toggleNav} to="/">{language === 'zh' ? '主页' : 'Home'}</Link>
                        <Link onClick={toggleNav} to="/#design">{language === 'zh' ? '服务' : 'Services'}</Link>
                        <Link onClick={toggleNav} to="/news">{language === 'zh' ? '新闻' : 'News'}</Link>
                        <Link onClick={toggleNav} to="/#About">{language === 'zh' ? '关于' : 'About'}</Link>
                        <Link onClick={toggleNav} to="https://forms.office.com/r/4AiVxeQLmp">{language === 'zh' ? '联系我们' : 'Contact'}</Link>
                        <div style={{color: '#fff', marginTop: '20px', cursor: 'pointer'}} onClick={changeLanguage}>中 | EN</div>
                        <IoClose style={{ color: 'white', fontSize: '30px', position: 'fixed', top: '28px', right: '18px', zIndex: '11' }} onClick={toggleNav} />
                    </ul>
                )}
            </div>
        </nav>
    );
};

export default Header;