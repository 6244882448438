// import { Parallax } from 'react-parallax';
// import Bg1 from '../img/home-bg1.svg';
// import Bg1 from '../img/home-bg1.jpg';

const HomeHeader = ({ language }) => (
    <div className='change-home-bg-position' >
        <div className='home-content'>
            <div style={{ maxWidth: '1000px', width: '90vw', display: 'flex', 'alignContent': 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="wow animate__animated animate__fadeInLeft shs" style={{ marginBottom: '30px', fontSize: '20px' }}>
                    {language === 'zh' ? 'Explore Protein-centric AI Technology' : ''}
                </div>
                <div className="wow animate__animated animate__fadeIn sh1" style={{ marginBottom: '60px', letterSpacing: '1px', fontSize: window.innerWidth < 800 ? (language === 'zh' ? '48px' : '36px') : (language === 'zh' ? '96px' : '72px'), animationDuration: '1.8s' }}>
                    {language === 'zh' ? '探索以蛋白质为核心的最前沿AI技术' : 'Explore Protein-centric AI Technology'}
                </div>
                <div className="wow animate__animated animate__fadeInRight shp" style={{ color: 'white', maxWidth: '700px', margin: 'auto', fontSize: '18px', letterSpacing: '1px' }}>
                    {language === 'zh' ? '我们致力于利用定制化的前沿AI技术充分发挥海量的蛋白质多模态数据潜力，帮助科研人员和医药企业高效、透明、可控地完成一系列以蛋白质为核心的计算任务，如蛋白质结构解析、蛋白质设计、药物性质预测等。' : '  We are committed to leveraging customized cutting-edge AI technology to fully unleash the potential of massive protein multimodal data, helping researchers and pharmaceutical companies efficiently, transparently, and controllably complete a series of protein-centric computational tasks, such as protein structure analysis, protein design, drug property prediction.'}
                </div>
                <a href='/pipeline' className='pipeline-link'>
                    <div>
                        {
                            language === 'zh' ? '最新进展 >>' : 'Lastest Progress >>'
                        }
                    </div>
                </a>
            </div>
        </div>
    </div>
);

export default HomeHeader;