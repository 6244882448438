import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import MV from '../img/home-Alg-mv.mov'

function HomeAlgorithm({ language }) {
    const [percentage1, setPercentage1] = useState(0);
    const [percentage2, setPercentage2] = useState(0);
    const [percentage3, setPercentage3] = useState(0);

    const { ref, inView } = useInView({
        threshold: 0.5, // 当元素50%可见时触发
        triggerOnce: true // 只触发一次
    });

    useEffect(() => {
        if (inView) {
            const interval = setInterval(() => {
                if (percentage1 < 100) {
                    setPercentage1(percentage1 + 1);
                }
                if (percentage2 < 100) {
                    setPercentage2(percentage2 + 1);
                }
                if (percentage3 < 100) {
                    setPercentage3(percentage3 + 1);
                }
            }, 10);

            return () => clearInterval(interval);
        }
    }, [inView, percentage1, percentage2, percentage3]);

    const content = language === 'en' ? (
        <>
            <div className="wow animate__animated animate__fadeInDown shs" style={{ color: '#A792F9', fontWeight: '700', marginBottom: '20px' }}>
                CORE TECH
            </div>
            <div className="sh2" style={{ color: '#fff', fontSize: '37.016px' }}>
                Cryo-EM Algorithm
            </div>
            <div className="shp" style={{ color: '#fff' }}>
                Cryo-electron microscopy technology captures images of protein structures at near-atomic resolution, and we particularly focus on the challenging task of continuous dynamic protein reconstruction. Our self-developed CryoFormer algorithm framework achieves continuous protein polymorph reconstruction in the time domain for the first time and can automatically highlight dynamic protein regions, showing great potential for downstream tasks such as protein property analysis and pharmaceuticals.
            </div>
        </>
    ) : (
        <>
            <div className="wow animate__animated animate__fadeInDown shs" style={{ color: '#A792F9', fontSize: '13.881px', fontWeight: '700', marginBottom: '20px' }}>
                Cryo-EM Reconstruction
            </div>
            <div className="sh2" style={{ color: '#fff', fontSize: '37.016px' }}>
                冷冻电镜算法
            </div>
            <div className="shp" style={{ color: '#fff' }}>
                冷冻电镜技术可以在近原子尺度捕捉到蕴含蛋白质的真实结构的图像，我们特别关注其中最具挑战的连续动态蛋白质重建任务。我们自研的CryoFormer算法框架第一次在时域实现了连续的蛋白质多态重建，并且可以自动高亮蛋白质动态区域，有着极大的潜力用于后续的蛋白质性质解析、制药等下游任务。
            </div>
        </>
    );

    return (
        <div id="algorithm" className='home-content' style={{ textAlign: 'left', backgroundColor: '#09072f', height: 'fit-content' }}>
            <div className='flex-center' style={{ maxWidth: '1400px', marginTop: '100px', marginBottom: '100px', width: '85vw' }}>
                <div className="divide-two" style={{ margin: '0 0 30px 0' }}>
                    {content}
                    <div ref={ref} className='wow animate__animated animate__zoomIn' style={{ margin: '50px 0' }}>
                        <div style={{ width: '120px', margin: '10px 30px', display: 'inline-block' }}>
                            <CircularProgressbar
                                value={percentage1}
                                text={language === 'en' ? `Continuous` : `连续`}
                                styles={{
                                    path: {
                                        stroke: `#A792F9`,
                                    },
                                    trail: {
                                        stroke: '#d6d6d6',
                                    },
                                    text: {
                                        fill: '#fff',
                                        fontSize: '11.5px',
                                    },
                                }}
                            />
                        </div>
                        <div style={{ width: '120px', margin: '10px 30px', display: 'inline-block' }}>
                            <CircularProgressbar
                                value={percentage2}
                                text={language === 'en' ? `Reconstruction` : `多态重建`}
                                styles={{
                                    path: {
                                        stroke: `#A792F9`,
                                    },
                                    trail: {
                                        stroke: '#d6d6d6',
                                    },
                                    text: {
                                        fill: '#fff',
                                        fontSize: '11.5px',
                                    },
                                }}
                            />
                        </div>
                        <div style={{ width: '120px', margin: '10px 30px', display: 'inline-block' }}>
                            <CircularProgressbar
                                value={percentage3}
                                text={language === 'en' ? `Highlight` : `自动高亮`}
                                styles={{
                                    path: {
                                        stroke: `#A792F9`,
                                    },
                                    trail: {
                                        stroke: '#d6d6d6',
                                    },
                                    text: {
                                        fill: '#fff',
                                        fontSize: '11.5px',
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <a href='https://cryoformer.github.io' className='wow animate__animated animate__flipInX normol-button-black' >{language === 'zh' ? '了解更多' : 'Learn More'}</a>
                </div>
                <div className="divide-two">
                    <video className='home-video' autoPlay loop muted controlsList="nodownload" onContextMenu={(e) => e.preventDefault()} playsInline>
                        <source src={MV} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>

            </div>
        </div>
    )
};

export default HomeAlgorithm;