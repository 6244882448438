import Img4 from '../img/home-bg2.svg';

const HomeAbout = ({language}) => (
    <div className='home-content' style={{ height: 'fit-content', padding: '60px 0 20px 0', backgroundColor: '#FAFAFA' }}>
        <a id='About' style={{position: 'relative', top: '-190px'}} href='/'> </a>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
            <div className='divide-two' style={{ transform: 'translateY(-100px)', position: 'relative' }}>
                <img className='wow animate__animated animate__fadeIn' src={Img4} alt="" style={{ height: '500px' }} />
                <a className="circle-box wow animate__animated animate__fadeIn" href="#design" style={{ width: '180px', height: '180px', left: '0', top: '0', animationDelay: '0.5s' }}>
                    {language === 'zh' ? '蛋白质' : 'Protein'} <br /> {language === 'zh' ? '设计' : 'Design'}
                </a>
                <a className="circle-box wow animate__animated animate__fadeIn animate__delay-0.5s" href="#predict" style={{ width: '150px', height: '150px', left: '5%', bottom: '0', animationDelay: '0.8s' }}>
                    {language === 'zh' ? '蛋白质' : 'Protein'} <br /> {language === 'zh' ? '预测' : 'Prediction'}
                </a>
                <a className="circle-box wow animate__animated animate__fadeIn" href="#algorithm" style={{ width: '200px', height: '200px', right: '0%', bottom: '20%', animationDelay: '1.1s' }}>
                    {language === 'zh' ? '蛋白质' : 'Protein'} <br /> {language === 'zh' ? '结构解析' : 'Structure Analysis'}
                </a>
            </div>
            <div className='divide-two' style={{ transform: 'translateY(-40px)', padding: '0 0 0 60px' }}>
                <div className="wow animate__animated animate__fadeInDown shs" style={{ color: '#525AF2', fontSize: '13.881px', fontWeight: '700', marginBottom: '20px' }}>
                    {language === 'zh' ? 'ABOUT US' : ''}
                </div>
                <div className="sh1" style={{ color: '#000', fontSize: '38px', letterSpacing: '1px' }}>
                    {language === 'zh' ? '关于寰渺' : 'About Us'}
                </div>
                <div className="shp" style={{ marginBottom: '40px', letterSpacing: '1px' }}>
                    {language === 'zh' ? ' 寰渺科技（Cellverse）是一个致力于将蛋白质领域的科研成果转化为真实生产力的年轻团队，尤其聚焦于蛋白质三维动态结构、蛋白质设计和药物研发领域。利用最新AI技术，我们旨在提出全新的蛋白质数据处理管线，整合多模态的蛋白质数据。作为初创团队，我们强调高效的协作，敏捷的迭代产品，鼓励多角度的深入思考与大胆创新，旨在推动人类对蛋白质在微观尺度下的认知，定义未来蛋白质数据处理的流程。' : '  Cellverse is a young team dedicated to transforming scientific achievements in the field of proteins into real productivity, with a particular focus on protein 3D dynamic structure, protein design, and drug development. With the help of the latest AI technology, we aim to build up a novel protein data processing pipeline that integrates multimodal protein data from computational methods and experimental methods. We emphasize efficient collaboration, agile iterative products, encourage multidimensional thinking, and bold innovation, aiming to promote the understanding of proteins at the microscale and define the future process of protein data processing.'}
                </div>
                <a href='/#mission' className='wow animate__animated animate__flipInX normol-button-black'>{language === 'zh' ? '了解更多' : 'Learn More'}</a>
            </div>
        </div>
    </div>
);

export default HomeAbout;