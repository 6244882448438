import WhiteLogo from "../img/logo_white.svg";


const Footer = ({language}) => (
    <div className='home-content' style={{ height: 'fit-content', padding: '60px 0 60px 0', backgroundColor: '#1C1C1C' }}>
        <div className='flex-center' style={{
            maxWidth: '1600px', width: '85vw', color: '#fff', justifyContent: 'space-evenly', alignItems: "flex-start"
        }}>
            <div className="footer1">
                <img src={WhiteLogo} alt="" />
                <div className="shp" style={{ color: '#fff' }}>
                    {language === 'zh' ? '探索以蛋白质为核心的最前沿AI技术' : 'Explore the most advanced AI technology centered on protein'}
                </div>
            </div>
            <div className="footer1">
                <div style={{ fontWeight: '800', marginBottom: '20px', fontSize: '1.5rem' }}>
                    {language === 'zh' ? '公司' : 'Company'}
                </div>
                <a href="/#About">{language === 'zh' ? '关于 Cellverse' : 'About Cellverse'}</a>
                {/* <a href="">Services</a> */}
                <a href="/news">{language === 'zh' ? '新闻' : 'News'}</a>
            </div>
            <div className="footer1">
                <div style={{ fontWeight: '800', marginBottom: '20px', fontSize: '1.5rem' }}>
                    {language === 'zh' ? '联系方式' : 'Contact'}
                </div>
                <span>{language === 'zh' ? '公司地址：中国上海浦东新区华夏中路393号' : 'Company Address: 393 Huaxia Middle Road, Pudong New Area, Shanghai, China'}</span>
                <span>{language === 'zh' ? '邮箱：contact@cellverse.tech' : 'Email: contact@cellverse.tech'}</span>
                <span>{language === 'zh' ? '公众号：Cellverse 寰渺科技' : 'WeChat: Cellverse 寰渺科技'}</span>
            </div>
        </div>
        <div className="shp" style={{ color: '#595762', marginTop: '40px' }}>
            {language === 'zh' ? '版权所有 © 2023-2033 寰渺科技Cellverse ICP备案号：' : 'Copyright © 2023-2033 Cellverse Technology ICP Record Number: '}
            <a href="https://beian.miit.gov.cn">{language === 'zh' ? '沪ICP备2023023375号-1 ' : 'Shanghai ICP No. 2023023375-1 '}</a>
            <br />
            {language === 'zh' ? '网站地址：www.cellverse.tech 域名：cellverse.tech' : 'Website address: www.cellverse.tech Domain: cellverse.tech'}
            <br />
            {language === 'zh' ? '公安网信办备案号：' : 'Public Security Network Information Office Record Number: '}
        </div>
    </div>
);

export default Footer;