import React, { useEffect } from 'react';
import Pipeline1 from '../img/Vectorpipeline1.svg';
import Pipeline2 from '../img/Vectorpipeline2.svg';
import Pipeline3 from '../img/Vectorpipeline3.svg';
const tableContent = {
    '靶向GPCR药物 ': [
        ["Confidential", "Confidential", "3", ""],
        ["GPR12", "自研", "2", "G蛋白偶联受体12，是一类孤儿受体，与人类的代谢疾病和短期记忆相关。"],
        ["GPR6", "自研", "1", "G蛋白偶联受体6，是一类孤儿受体，与肥胖相关。"],
        ["GPR3", "自研", "1", "G蛋白偶联受体3，是一类孤儿受体，与肥胖相关。"],
        ["Prostaglandin E receptor", "自研", "2", "前列腺素E4 受体，抑制前列腺素EP4受体已被证明可以抑制肿瘤生长、血管生成、淋巴管生成和转移。"],
    ],
    '传染性疾病': [
        ["SARS-CoV-2 RBD Domain", "自研", "2", "新冠病毒受体结合区"],
        ["Rabies virus glycoprotein", "自研", "1", "狂犬病毒糖蛋白"],
        ["Rabies virus nuclear protein", "自研", "1", "狂犬病毒核蛋白"],
    ],
    '肿瘤': [
        ["PD1", "自研", "2", "程序性细胞死亡蛋白1，与肿瘤免疫相关。"],
        ["PD-L1", "自研", "1", "程序化死亡配体1，与肿瘤免疫相关。"],
    ],
    '抗体偶联药物': [
        ["HER2", "自研", "1", "受体酪氨酸蛋白激酶erbB-2，是约30%乳腺癌患者的重要生物标志物和治疗靶点。"],
        ["Nectin-4", "自研", "1", "细胞粘附分子4，与癌症的发展有关。"],
    ],
    '实验室工具蛋白': [
        ["Fusion protein", "Confidential", "3", "融合蛋白，是一类结构生物学工具蛋白。"],
    ],
    '生物材料': [
        ["Confidential", "贻如生物", "2", ""],
    ],
};

const PipelineHead = ({language}) => {
    return (
        <div className='home-content' style={{ height: 'fit-content', padding: '100px 0' }}>
            <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
                <div>
                    <div className="wow animate__animated animate__fadeInLeft shs" style={{ color: '#fff' }}>
                        Latest Progress
                    </div>
                    <div className="wow animate__animated animate__fadeInUp sh1" style={{ color: '#fff' }}>
                        {language === 'zh' ? '蛋白质设计管线' : 'Protein Design Pipeline'}
                    </div>
                    <div className="wow animate__animated animate__fadeInUp shp" style={{ color: '#fff', marginBottom: '50px', padding: '30px', borderRadius: '5px' }}>
                        {language === 'zh' ? '我们一直以来致力于将庞大数据和深度学习的力量注入到生命科学领域，以努力解决各类与人类生命健康息息相关的重大问题。目标受体涵盖：G 蛋白偶联受体、各类传染病抗原、肿瘤、抗体偶联药物靶点，以及一些实验室通用工具蛋白、生物材料等。' : 'We have been committed to injecting the power of huge data and deep learning into the field of life sciences to solve all kinds of major problems related to human life and health. Target receptors include: G protein-coupled receptors, various infectious disease antigens, tumors, antibody coupled drug targets, as well as some common laboratory tool proteins, biological materials, etc.'}
                    </div>
                </div>
            </div>
        </div>
    );
}

const PipelineContent = () => {
    useEffect(() => {
        const description = document.getElementsByClassName('pipeline-progress-first-td');
        const descriptionBox = document.getElementsByClassName('pipeline-description');
        for (let i = 0; i < description.length; i++) {
            description[i].addEventListener('mouseover', () => {
                descriptionBox[i].style.display = 'block';
            });
            description[i].addEventListener('mouseout', () => {
                descriptionBox[i].style.display = 'none';
            });
        }
    }, []);

    return (
        <div className='pipeline-container'>
            <div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr className='pipeline-header'>
                            <th className="pipeline-header-th" >
                                {/* style={{background: 'linear-gradient(180deg, #4A4857 0%, rgba(74, 72, 87, 0.00) 100%)'}} */}
                                <div style={{ background: 'rgba(255, 255, 255, 0.14)', padding: '30px 40px', display: 'inline-block' }}>
                                    靶点
                                </div>
                            </th>
                            <th className="pipeline-header-th" >
                                {/* style={{background: 'linear-gradient(180deg, #4A4857 0%, rgba(74, 72, 87, 0.00) 100%)'}} */}
                                <div style={{ background: 'rgba(255, 255, 255, 0.14)', padding: '30px 30px', display: 'inline-block' }}>
                                    合作方
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline1} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '7' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    设计
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline2} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '6' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-45%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    表达
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline2} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '5' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-40%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    功能<br />
                                    （细胞水平）
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline2} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '4' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-40%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    功能<br />
                                    （动物水平）
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline2} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '3' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-40%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    新药临床<br />
                                    试验申请
                                </div>
                            </th>
                            <th className="pipeline-header-th">
                                <img src={Pipeline3} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '110%', zIndex: '2' }} />
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-40%, -50%)', color: '#fff', zIndex: '8', width: '100%' }}>
                                    1期临床试验
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ width: '100%', height: '2px', background: '#fff' }}>
                            <td colspan="8"></td>
                        </tr>
                        <tr className='pipeline-section' style={{ backgroundColor: 'transparent' }}>
                            <td colspan="8"></td>
                        </tr>
                    </tbody>
                    {
                        Object.keys(tableContent).map((section, _) => (
                            <tbody>
                                <tr className='pipeline-section'>
                                    <td colspan="8">{section}</td>
                                </tr>
                                <tr className='pipeline-section' style={{ backgroundColor: 'transparent' }}>
                                    <td colspan="8"></td>
                                </tr>
                                {tableContent[section].map((row, lineIndex) => {
                                    return (
                                        <tr key={lineIndex} className='pipeline-progress'>
                                            {row.map((cell, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <td className='pipeline-progress-td pipeline-progress-first-td' key={index}>
                                                            {cell}
                                                            <div className="pipeline-description" style={{ opacity: tableContent[section][lineIndex][3] ? 1 : 0 }}>
                                                                {tableContent[section][lineIndex][3]}
                                                            </div>
                                                        </td>
                                                    )
                                                } else if (index === 1) {
                                                    return (
                                                        <td className='pipeline-progress-td pipeline-progress-second-td' key={index}>
                                                            <div style={{ display: 'inline-block', background: '#464646', padding: '0 8px' }}>
                                                                {cell}
                                                            </div>
                                                        </td>
                                                    )
                                                } else if (index === 2) {
                                                    return (
                                                        <td className='pipeline-progress-td' key={index} colSpan={parseInt(cell)} style={{ borderLeft: '1px solid #fff' }}>
                                                            <div className="pipeline-done pipeline-animate" />
                                                        </td>
                                                    )
                                                } else {
                                                    return <td key={index}></td>;
                                                }
                                            })}
                                        </tr>
                                    )
                                })}
                                <tr className='pipeline-section' style={{ backgroundColor: 'transparent' }}>
                                    <td colspan="8"></td>
                                </tr>
                            </tbody>
                        ))
                    }
                </table>
            </div>
        </div>
    );
}

const HomePipeline = () => {
    return (
        <div>
            <PipelineHead />
            <PipelineContent />
        </div>
    );
};


export default HomePipeline;