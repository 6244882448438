import { React, Component } from 'react';
import { Helmet } from 'react-helmet';
import 'animate.css';
import WOW from 'wow.js';
import Header from './components/Header';
import NewsHeader from './components/NewsHeader';
import NewsContent from './components/NewsContent';
import Footer from './components/Footer';
import HomeCooperation from './components/HomeCooperation';
import Logo from './img/logo.png';

class News extends Component {
    componentDidMount() {
        new WOW().init();
    }

    render() {
        const { language } = this.props; // Add language prop

        return (
            <div>
                <Helmet>
                    <title>Cellverse | News</title>
                    <meta
                        name="description"
                        content="Explore the cutting edge AI technology at the core of proteins"
                    />
                    <link rel="apple-touch-icon" href={Logo} />
                    <meta property="og:title" content="Cellverse" />
                    <meta property="og:description" content="探索以蛋白质为核心的最前沿AI技术" />
                    <meta property="og:image" content={Logo} />
                </Helmet>
                <Header language={language} />
                <div style={{ width: '100%', height: '89.5px', backgroundColor: '#060024' }}></div>
                <NewsHeader language={language} />
                <NewsContent language={language} />
                <HomeCooperation language={language} />
                <Footer language={language} />
            </div>
        );
    }
}

export default News;
