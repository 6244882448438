import bg from '../img/home-contact.svg';

const HomeContact = ({ language }) => (
    <div id='contact' className='home-content' style={{ height: 'fit-content', backgroundColor: '#5B38E5' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
            <div className='divide-two'>
                <div className="wow animate__animated animate__fadeInLeft sh2" style={{ color: '#fff', fontSize: '66px', fontFamily: 'ALBB-B', fontWeight: '800' }}>
                    {language === 'en' ? 'Ready to Join Us?' : '联系我们？'}
                </div>
                <a href="https://forms.office.com/r/4AiVxeQLmp">
                    <div className='wow animate__animated animate__fadeInLeft normol-button-black-contact' style={{ marginLeft: '10px' }}>{language === 'en' ? 'Contact Us' : '填写问卷'}</div>
                </a>
            </div>
            <div className='divide-two'>
                <img className="wow animate__animated animate__fadeInUp" src={bg} alt="" style={{ height: '500px' }} />
            </div>
        </div>
    </div>
);

export default HomeContact;