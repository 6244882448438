import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'animate.css';
import WOW from 'wow.js';
import Header from './components/Header';
import HomeHeader from './components/HomeHeader';
import HomeAbout from './components/HomeAbout';
import HomeAlgorithm from './components/HomeAlgorithm';
import HomePredict from './components/HomePredict';
import HomeDesign from './components/HomeDesign';
import HomeContact from './components/HomeContact';
import HomeMission from './components/HomeMission';
import Footer from './components/Footer';
import Cooperation from './components/HomeCooperation';
import Logo from './img/logo.png';

function Home({language}) {
  useEffect(() => {
    new WOW().init();

    setTimeout(() => {
      const url = window.location.href;
      const index = url.indexOf('#');

      if (index !== -1) {
        const id = url.substring(index + 1);
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 200); // Pause for 2 seconds (2000 milliseconds)
  }, []);

  return (
    <div>
      <Helmet>
        <title>Cellverse | Home</title>
        <meta
          name="description"
          content="Explore the cutting edge AI technology at the core of proteins"
        />
        <link rel="apple-touch-icon" href={Logo} />
        <meta property="og:title" content="Cellverse" />
        <meta property="og:description" content="探索以蛋白质为核心的最前沿AI技术" />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <Header language={language} />
      <div style={{ width: '100%', height: '89.5px', backgroundColor: '#060024' }}></div>
      <HomeHeader language={language} />
      <HomeAbout language={language} />
      <HomeDesign language={language} />
      <HomePredict language={language} />
      <HomeAlgorithm language={language} />
      <HomeMission language={language} />
      <Cooperation language={language} />
      <HomeContact language={language} />
      <Footer language={language} />
    </div>
  );
}

export default Home;
