import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './Home';
import News from './News';
import Pipeline from './Pipeline';
// import CustomCardGenerator from './CustomCardGenerator';
import './App.css';
import './css/style.css';
import './css/nav.css'

// Detect user's language preference
const userLanguage = navigator.language || navigator.userLanguage;
let language = localStorage.getItem('language') || (userLanguage.startsWith('zh') ? 'zh' : 'en');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Router>
    <Routes> {/* 使用 Routes 组件包裹 Route */}
      <Route path="/" exact element={<Home language={language} />} />
      <Route path="/home" exact element={<Home language={language} />} />
      <Route path="/news" exact element={<News language={language} />} />
      <Route path="/pipeline" exact element={<Pipeline language={language} />} />
      {/* <Route path="/customcard" exact element={<CustomCardGenerator />} /> */}
    </Routes>
  </Router>
  // <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
