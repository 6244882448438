import Rocket from '../img/Rocket.svg';
import Goal from '../img/Goal.svg';
import Morale from '../img/Morale.svg';


const HomeMission = ({ language }) => (
    <div id='mission' className='home-content' style={{ height: 'fit-content', padding: '80px 0 40px 0', backgroundColor: '#FAFAFA' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw', flexDirection: 'column' }}>
            <div className="wow animate__animated animate__fadeInLeft shs" style={{ color: '#A792F9', fontWeight: '700', marginBottom: '10px' }}>
                {/* WHY CHOOSE US */}
            </div>
            <div className="wow animate__animated animate__fadeInDown sh1" style={{ marginBottom: '40px', color: 'black', fontSize: '36px' }}>
                {language === 'zh' ? '核心理念' : 'CORE CONCEPT'}
            </div>
            <div className='flex-center' style={{ width: '100%', justifyContent: 'space-evenly' }}>
                <div className='wow animate__animated animate__bounceInUp home7-box'>
                    <img src={Rocket} alt="" style={{margin: '30.5px 0'}} />
                    <div style={{ fontSize: '20px', fontWeight: '600', letterSpacing: '1px', marginBottom: '12px' }}>OUR MISSION</div>
                    <div className="shp" style={{}}>
                        {language === 'zh' ? '利用最前沿的AI技术帮助人类更好的理解、设计蛋白质。' : 'Using cutting-edge AI technology to help human better understand and design protein.'}
                    </div>
                </div>
                <div className="wow animate__animated animate__bounceInUp home7-box">
                    <img src={Goal} alt="" />
                    <div style={{ fontSize: '20px', fontWeight: '600', letterSpacing: '1px', marginBottom: '12px' }}>OUR VISION</div>
                    <div className="shp">

                        {language === 'zh' ? '成为AI蛋白质数据处理领域的引领者' : 'Becoming the leader in the field of protein-centric AI. '}
                    </div>
                </div>
                <div className="wow animate__animated animate__bounceInUp home7-box">
                    <img src={Morale} alt="" />
                    <div style={{ fontSize: '20px', fontWeight: '600', letterSpacing: '1px', marginBottom: '12px' }}>OUR VALUE</div>
                    <div className="shp">
                        {language === 'zh' ? '负责、合作、创新' : 'responsible, cooperative, innovative'}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default HomeMission;