import Img5 from '../img/home-bg5.jpg';

const HomeDesign = ({ language }) => (
    <div id="design" className='home-content' style={{ height: 'fit-content', padding: '0px 0', backgroundColor: '#000214' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
            <div className='divide-two' style={{ margin: '30px 0' }}>
                <div className="wow animate__animated animate__fadeInDown shs" style={{ color: '#A792F9', fontSize: '13.881px', fontWeight: '700', marginBottom: '20px' }}>
                    {language === 'en' ? 'OUR SERVICES' : 'Protein Design'}
                </div>
                <div className="sh1" style={{ color: '#fff', fontSize: '37.016px' }}>
                    {language === 'en' ? 'Protein Design' : '蛋白质设计'}
                </div>
                <div className="shp" style={{ color: '#fff', marginBottom: '40px' }}>
                    {language === 'en' ? 'The BisignNovo platform developed by Bisign, the structural design team of Cellverse, can design small protein inhibitors with high affinity, stability, and easy expression for any target protein, providing reliable and efficient solutions for signal pathway research and the development of large-molecule protein inhibitors.' : '  寰渺科技结构设计团队Bisign开发的BisignNovo平台，可以针对任何目标蛋白设计具有高亲和力、高稳定性、易表达的小蛋白抑制剂，为信号通路研究、大分子蛋白质抑制剂的开发提供可靠且高效的解决方案。'}
                </div>
                <a href='https://mp.weixin.qq.com/s/cjs5Z-TcIhS08QvqEzyBkQ' className='wow animate__animated animate__flipInX normol-button-black' >{language === 'en' ? 'Learn More' : '了解更多'}</a>
            </div>
            <div className='divide-two'>
                <img src={Img5} alt="" />
            </div>
        </div>
    </div>
);

export default HomeDesign;