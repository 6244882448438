import Coor1 from '../img/coor-synmetabio-compress.png';
import Coor2 from '../img/coor-shlc.png'

const HomeCooperation = ({ language }) => (
    <div id='cooperation' className='home-content' style={{ height: 'fit-content', padding: '80px 0 100px 0', backgroundColor: '#fff' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw', flexDirection: 'column' }}>
            <div className="wow animate__animated animate__fadeInLeft shs" style={{ color: '#A792F9', fontWeight: '700', marginBottom: '10px' }}>
                {language === 'zh' ? 'BUSINESS PARTNER' : ''}
            </div>
            <div className="wow animate__animated animate__fadeInDown sh1" style={{ marginBottom: '40px', color: 'black', fontSize: '36px' }}>
                {language === 'zh' ? '合作伙伴' : 'BUSINESS PARTNER'}
            </div>
            <div className='flex-center' style={{ width: '100%', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <a href="https://clinic.shanghaitech.edu.cn/">
                    <img src={Coor2} alt="" style={{ width: '400px', margin: '20px 30px', maxWidth: '80vw' }} />
                </a>
                <a href="http://www.synmetabio.com">
                    <img src={Coor1} alt="" style={{ width: '400px', margin: '20px 30px', maxWidth: '80vw' }} />
                </a>
            </div>
        </div>
    </div>
);

export default HomeCooperation;