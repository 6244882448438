import React from 'react';
import { useState } from 'react';
import Haoge from '../img/news-hao-ge-yan-jiang.jpeg';
import Protein from '../img/news-protein.svg';
import Shortlist from '../img/news-shortlist.png';


// const Item = ({image, title, url, content}) => (
//     <a href="https://mp.weixin.qq.com/s/bfDqJrFGxYvLMeWUBm802w" className='flex-center news-box' style={{ maxWidth: '1200px', width: '85vw', margin: "20px auto" }}>
//         <div className='divide-two news-box-img'>
//             <img src={Haoge} alt="" />
//         </div>
//         <div className='divide-two' style={{ maxWidth: '600px' }}>
//             <div className="wow animate__animated animate__zoomIn nct" style={{ color: '#000' }}>
//                 寰渺科技获奇绩创坛数百万融资
//             </div>
//             <div className="wow animate__animated animate__zoomIn ncp">
//                 寰渺科技成立于2022年6月，于2022年11月22日在北京国家会展中心参加奇绩创坛路演日，顺利与奇绩创坛签订数百万人民币的种子轮融资。
//             </div>
//             <div className="news-time">2022年11月22日</div>
//         </div>
//     </a>
// );

const Item = ({ image, title, url, content, time }) => (
    <a href={url} className='flex-center news-box' style={{ maxWidth: '1600px', width: '85vw', margin: "60px auto", alignItems: "flex-start" }}>
        <div className='divide-two news-box-img'>
            <img src={image} alt="" />
        </div>
        <div className='divide-two' style={{ maxWidth: '600px', minHeight: '400px', padding: '0 5px' }}>
            <div className="wow animate__animated animate__zoomIn nct" style={{ color: '#000' }}>
                {title}
            </div>
            <div className="wow animate__animated animate__zoomIn ncp">
                {content}
            </div>
            <div className="news-time">{time}</div>
        </div>
    </a>
);


const NewsContent = () => {
    const itemsPerPage = 5; // 每页显示的项目数
    const totalItems = 1; // 总共的项目数
    // eslint-disable-next-line
    const totalPages = Math.ceil(totalItems / itemsPerPage); // 计算总页数

    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(1); // 当前页码

    // 计算当前页要显示的项目范围
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

    // 生成当前页的项目组件
    const currentPageItems = [];
    // for (let i = startIndex; i < endIndex; i++) {
    // }
    currentPageItems.push(
        <Item key={2} index={2}
            image={Shortlist}
            title="寰渺科技入选2024年上海市浦东新区第一批创新型中小企业名单"
            url="https://mp.weixin.qq.com/s/PK6jjOe8lO0ernqxYfX5Jw"
            content="在2024年上海市浦东新区第一批创新型中小企业申请中，一共有76家企业最终脱颖而出，寰渺科技也成功入选。"
            time="2024年4月28日" />
    );
    currentPageItems.push(
        <Item key={1} index={1}
            image={Haoge}
            title="寰渺科技获奇绩创坛数百万融资"
            url="https://mp.weixin.qq.com/s/bfDqJrFGxYvLMeWUBm802w"
            content="寰渺科技成立于2022年6月，于2022年11月22日在北京国家会展中心参加奇绩创坛路演日，顺利与奇绩创坛签订数百万人民币的种子轮融资。"
            time="2022年11月22日" />
    );
    currentPageItems.push(
        <Item key={0} index={0}
            image={Protein}
            title="AI for science：寰渺科技打造Bisign-Novo平台从头设计全新功能蛋白质"
            url="https://mp.weixin.qq.com/s/cjs5Z-TcIhS08QvqEzyBkQ"
            content="寰渺科技的BisignNovo蛋白质设计平台在上海张江高新技术区开展广泛的内部测试，与合作的多家高校实验室开展了近十项突破性科研项目，其中包括新型PD1抗体的设计、某GPCR的小蛋白抑制剂、某GPCR的多肽激活/抑制剂，以及生物技术工具蛋白等。"
            time="2023年11月2日" />
    );



    // eslint-disable-next-line
    const changePage = (page) => {
        setCurrentPage(page);
    };

    return (
        <div id='About' className='home-content' style={{ height: 'fit-content', padding: '60px 0 20px 0', backgroundColor: '#FAFAFA' }}>
            {currentPageItems}
            {/* <div className="pagination" style={{marginTop: '100px'}}>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => changePage(index + 1)}
                        className={currentPage === index + 1 ? 'news-page-button news-page-button-activate' : 'news-page-button'}
                    >
                        {index + 1}
                    </button>
                ))}
            </div> */}
        </div>
    );
};


export default NewsContent;