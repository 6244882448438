import Img4 from '../img/home-bg4.jpg';

const HomePredict = ({ language }) => (
    <div id='predict' className='home-content' style={{ height: 'fit-content', padding: '0px 0', backgroundColor: '#000214' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
            <div className='divide-two'>
                <img src={Img4} alt="" />
            </div>
            <div className='divide-two' style={{ margin: '30px 0' }}>
                <div className="wow animate__animated animate__fadeInDown shs" style={{ color: '#A792F9', fontSize: '13.881px', fontWeight: '700', marginBottom: '20px' }}>
                    {language === 'en' ? 'OUR SERVICES' : 'Protein Prediction'}
                </div>
                <div className="sh1" style={{ color: '#fff', fontSize: '37.016px' }} >
                    {language === 'en' ? 'Protein Prediction' : '蛋白质预测'}
                </div>
                <div className="shp" style={{ color: '#fff', marginBottom: '40px' }}>
                    {language === 'en' ? "Cellverse's protein structure prediction model algorithm is based on state-of-the-art deep learning technology. By learning from a large number of known protein structures (especially the structures of G protein-coupled receptors in different states), it can accurately predict the structure of unknown proteins. Our service utilizes high-performance computing resources and can complete protein structure prediction in a short period of time, improving work efficiency. At the same time, we support customized services according to customer needs, providing personalized protein structure prediction solutions." : '寰渺科技蛋白质结构预测模型算法基于最先进的深度学习技术，通过对大量已知蛋白质结构的学习（特别是G蛋白偶联受体不同状态的结构），能够以高精度预测未知蛋白质的结构。我们的服务采用高性能计算资源，能够在短时间内完成蛋白质结构的预测，提高工作效率，同时支持根据客户需求进行定制化服务，提供个性化的蛋白质结构预测解决方案。'}
                </div>
                <a href='https://mp.weixin.qq.com/s/cjs5Z-TcIhS08QvqEzyBkQ' className='wow animate__animated animate__flipInX normol-button-black'>{language === 'en' ? 'Learn More' : '了解更多'}</a>
            </div>
        </div>
    </div>
);

export default HomePredict;