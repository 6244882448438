import bg from '../img/news-bg1.svg';


const NewsHeader = ({language}) => (
    <div className='home-content' style={{ height: 'fit-content', padding: '0px 0 0px 0', backgroundColor: '#060024' }}>
        <div className='flex-center' style={{ maxWidth: '1600px', width: '85vw' }}>
            <div className='divide-two'>
                <div className="wow animate__animated animate__fadeInLeft shs" style={{ color: '#fff' }}>
                    {/* 最近活动 */}
                </div>
                <div className="wow animate__animated animate__fadeInUp sh1" style={{ color: '#fff' }}>
                    {language === 'zh' ? '新闻' : 'NEWS'}
                </div>
                <div className="wow animate__animated animate__fadeInUp shp" style={{ color: '#fff', marginBottom: '50px' }}>
                    {language === 'zh' ? '微信公众号：' : 'WeChat Official Account: '}
                    Cellverse 寰渺科技
                </div>
            </div>
            <div className='divide-two'>
                <img className='wow animate__animated animate__fadeInRight' src={bg} alt="" />
            </div>
        </div>
    </div>
);

export default NewsHeader;